const applogins = {
  // Local :::::

  // BASE_API_URL: 'http://localhost:8000/v2/',
  // BASE_URL: 'http://localhost:3000/',
  // payment_url: 'http://localhost:3001/',
  //

  // Live :::::
  BASE_API_URL: 'https://api2.avdaat.biz/v2/',
  BASE_URL: 'http://studios.avdaat.biz/',
  payment_url: 'http://payments.avdaat.biz/',


  //
  //Staging :::::
  // BASE_API_URL: "http://test-api.interiorlanes.in/api/",
  //BASE_URL:'http://staging.interiorlanes.in/',

  FILES_API_URL: 'https://api2.avdaat.biz/files/',
  //FILES_API_URL: 'http://localhost:8000/files/',


  //MENU TYPE::::: Possible Values['TPA','LPA','STUDIO']
  // TPA =>Testing Provider Associate
  //LPA =>Lead Provider Asscociate//
  MENU_TYPE: 'STUDIO',

  Token_name: 'nexusAuthTokenv1'
};
export default applogins;
